import React, { Component } from 'react';
import {Image, Transformation} from 'cloudinary-react';

export default class AllSetPreferences extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount() {
    this.startAtTop()
  }

  render() {
    return (
      <div>
        <div className="allSetContainer">
          <h1>Freshly tuned, {this.props.name}!</h1>
          <div className="allSetContent">
            <Image cloudName="scenerie" clientHints="true" publicId="signup/photo-1547637589-f54c34f5d7a4_hnekjs" alt="Freshly tuned!">
              <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
            </Image>
            <p>We received your updated preferences.</p>
            <p>Your Scenerie Retreats will be as personalized as ever.</p>
          </div>
        </div>
      </div>
    )
  }
}
