import React, { Component } from 'react';


export default class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          step: 1,
          title: 'Before you go',
          text: "You will receive a detailed itinerary and packing list right after you book via email. When it's time to leave, we text you last minute details and (depending on your itinerary) may send you recipe options so you can plan for your grocery shopping."
        },
        {
          step: 2,
          title: 'On your retreat',
          text: "Every morning we will text you activity cards for the day. Each activity card has all the details for an activity (going hiking, going out for dinner, preparing a meal) and also gives you guidance on truly slowing down and staying in the moment (we know this can be hard sometimes)."
        },
        {
          step: 3,
          title: 'Keep the memory',
          text: "Upload 3 pictures that you took during your retreat and were meaningful to you. We will then send you a personalized card (via good old snail mail) as a memory to stay relaxed until your next retreat with us."
        },
      ]
    }
  }
  render() {
    return (
      <div id="howItWorks" className="desktopWrapper">
        <div className="detailsWrapper">
          <h2>How a Scenerie Retreat works</h2>
          <div className="howItWorks_slider">
            {this.state.steps.map((eachStep, index) =>
            <div className="howItWorks_steps" id={index} key={eachStep.step}>
              <h1>{eachStep.step}</h1>
              <h3>{eachStep.title}</h3>
              <p>{eachStep.text}</p>
            </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
