import React, { Component } from 'react';
import {Image, Transformation} from 'cloudinary-react';

export default class AllSet extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount() {
    this.startAtTop()
  }

  render() {
    return (
      <div>
        <div className="allSetContainer">
          <h1>Welcome to the club, {this.props.name}!</h1>
          <div className="allSetContent">
            <Image cloudName="scenerie" clientHints="true" publicId="signup/photo-1496516348160-24b35a31856f_yssm5e" alt="You're in!">
              <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
            </Image>
            <p>Now quickly make sure that you received the confirmation email we sent you.</p>
            <p>Then sit back & relax - until your first Scenerie Retreat hits your inbox this Wednesday!</p>
          </div>
        </div>
      </div>
    )
  }
}
