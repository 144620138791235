import React, {Component} from 'react';
import axios from "axios";
import {withRouter} from 'react-router';
import MobileHead from './MobileHead';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_Xk9dVlWPlcZsJbgJvZ1yXP2Z001LVHZCaO');

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "/retreats/booking/1",
      stripeClientSecret: '',
      width: 0,
      object: this.props.location.state.object,
      retreat: this.props.location.state.retreat,
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      promoCode: this.props.location.state.promoCode,
      total: this.props.location.state.total,
    };
    window.addEventListener("resize", this.update);
  }

  startAtTop = () => window.scrollTo(0, 0);

  componentDidMount() {
    this.startAtTop()
    this.setState({
      width: window.innerWidth
    });
    axios.post(
      'https://api.scenerie.co/api/create-payment-intent',
      {
        "id": this.state.retreat,
        "start_date": this.state.startDate,
        "end_date": this.state.endDate,
        "promo_code": this.state.promoCode,
        "total_amount": this.state.total,
      },
    ).then((result) => this.setState({ stripeClientSecret: result.data.clientSecret }));
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  // TODO: Handle email automatically (e.g. fill it out)
  render() {
    return (
      <div id="booking">
        {(this.state.width < 480) ?  (
          <MobileHead link={this.state.link} />
        ) : (
          <h1 className="logo_fullScreen">Scenerie</h1>
        )}
        <h1>Your Payment Details</h1>
        <div className="bookingWithStripe">
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm clientSecret={this.state.stripeClientSecret} />
          </Elements>
        </div>
      </div>
    )
  }
}

export default withRouter(Step2)
