import React, { Component } from 'react'
import {Image, Transformation} from 'cloudinary-react';
import { getDay, getMonth} from './DateUtil'


export default class RetreatCover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleImg: this.props.object.titleImg,
      title: this.props.object.title,
      distance: this.props.object.property.distance,
      guests: this.props.object.property.guests,
      price: this.props.object.property.price,
      dates: this.props.object.dates,
      matchDateAvailable: this.props.object.matchDateAvailable,
    };
  }

  render() {
    return (
      <div id="retreatCover" className="desktopWrapper">
        <div className="retreatCover_img">
          <Image cloudName="scenerie" clienthints="true" publicId={this.state.titleImg} alt="">
            <Transformation dpr="auto" width="auto:100:1300" fetchFormat="auto" crop="fit" />
          </Image>
        </div>
        <div className="retreatCover_split">
          <h1>{this.state.title}</h1>
          <hr/>
          <div className="retreatCover_description">
            {this.state.matchDateAvailable &&
              <p>{getMonth(this.state.dates[0].start)}/{getDay(this.state.dates[0].start)} - {getMonth(this.state.dates[0].end)}/{getDay(this.state.dates[0].end)}</p>
            }
            {!this.state.matchDateAvailable && this.state.dates.length === 1 &&
              <p style={{color:'#a0a0a1'}}><strong><em>currently not available</em></strong></p>
            }
            {!this.state.matchDateAvailable && this.state.dates.length > 1 &&
              <p style={{color:'#a0a0a1'}}><strong><em>new dates available</em></strong></p>
            }
          </div>
          <hr/>
          <div className="retreatCover_description">
            <img src="/img/icons/car2.png" alt="."/>
              <p>{this.state.distance} driving distance</p>
          </div>
          <hr/>
          <div className="retreatCover_description">
            <img src="/img/icons/person.png" alt="."/>
            <p>Sleeps up to {this.state.guests} people</p>
          </div>
          <hr/>
          <div className="retreatCover_description">
            <p>${this.state.price} per night</p>
          </div>
          <hr/>
        </div>
      </div>
    )
  }
}
