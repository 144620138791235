import React, { Component } from "react";
import Video from "../Signup/Video";
import TextLoop from "react-text-loop";

export default class Cover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: "Ready for your summer escape?",
      width: 0,
    };
    window.addEventListener("resize", this.update);
  }

  startAtTop = () => window.scrollTo(0, 0);

  componentDidMount() {
    this.startAtTop();
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  jump() {}

  render() {
    const noWrap = (this.state.width < 1000) ? (false) : (true);
    console.log(noWrap)
    return (
      <div id="cover" className="signup_desktop">
        <Video />
        <div className="coverContent">
          <div className="coverContent_title">
            <h1>Slowing down made easy.</h1>
            <div className="coverBtn">
              <button onClick={this.props.jump}>
                <img
                  className="learnMore"
                  src="/img/icons/arrow_grey.png"
                  alt="Start here"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
