import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Image, Transformation} from 'cloudinary-react';

class TravelMates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
    };
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
      setTimeout(() => {
           this.setState({didMount: true})
       }, 0)
  }


  render() {
    return (
      <div id="signup_vibe" className={`signup_desktop_content`}>
        <h1>Who will likely travel with you?</h1>
        <div className="vibeContainer">
          {travelMates.map((eachInspiration) => {
            return (
              <div
                onClick={this.props.selected.bind(this, eachInspiration.key)}
                className={this.props.travelMates === eachInspiration.key ? "vibeCardSelected" : "vibeCard"}
                key={eachInspiration.id}
                value={eachInspiration.id}
              >
                <Image cloudName="scenerie" publicId={eachInspiration.img} alt={eachInspiration.description}>
                  <Transformation dpr="auto" width="auto:100:500" fetchFormat="auto" crop="fit" />
                </Image>
                <div className="vibeCard_description">
                  <p>{eachInspiration.description}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <button className={this.props.travelMates ? null : "hide"} onClick={this.props.next}>Next</button>
        </div>
      </div>
    )
  }
}

export default withRouter(TravelMates);

export const travelMates = [
  {
    id: 1,
    key: "solo",
    description: "Just me",
    img: "signup/photo-1594927974337-cca01748ec4b_jiy0xo"
  },
  {
    id: 2,
    key: "partner",
    description: "My partner",
    img: "signup/photo-1561287495-a3fe1fd28504_pthyfz"
  },
  {
    id: 3,
    key: "friends",
    description: "My friends",
    img: "signup/photo-1511988617509-a57c8a288659_pmumen"
  },
  {
    id: 4,
    key: "family",
    description: "My family",
    img: "signup/photo-1549068294-04a001ee0638_o4f8gu"
  },
]
