import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Select from 'react-select'
import { getDay, getMonth} from './DateUtil'


export default class BookRetreat extends Component {
  
  constructor(props) {
    super(props);
    var matchDateAvailable = this.props.object.matchDateAvailable;
    var dates = this.props.object.dates;
    var defaultValue = dates[0];
    if (!matchDateAvailable && dates.length > 1) {
      defaultValue = dates[1];
    }
    this.state = {
      price: this.props.object.property.price,
      total: this.props.object.property.price * 2,
      retreat: this.props.retreat,
      matchDateAvailable: matchDateAvailable,
      matchDate: dates[0],
      startDate: defaultValue.start,
      endDate: defaultValue.end,
    };
    this.book = this.book.bind(this);
  }

  book() {
    ReactGA.event({
      category: 'Retreat',
      action: this.state.retreat + ' jumped to booking summary',
    }); 
  }

  handleChange = (d) => {
    this.setState({ startDate: d.start , endDate: d.end });
  }

  getOptionValue = (d) => {
    return d
  }

  getOptionLabel = (d) => {
    return getMonth(d.start) + "/" + getDay(d.start) + " - " + getMonth(d.end) + "/" + getDay(d.end);
  }

  isOptionDisabled = (d) => {
    return !(this.state.matchDateAvailable || (d.start !== this.state.matchDate.start))
  }

  render() {
    var sortedDates = this.props.object.dates.sort(function (a, b) {
      return a.start.localeCompare(b.start);
    })
    var disabled = this.state.startDate === this.state.matchDate.start && !this.state.matchDateAvailable;
    return (
      <div id="bookRetreat">
        <div id="price">
          <p id="pricePerNight">${this.state.price} per night</p>
          <p id="totalPrice">(${this.state.total} total)</p>
        </div>
        <div id="book">
          <Select
            id="dates"
            options={sortedDates}
            defaultValue={{'start': this.state.startDate, 'end': this.state.endDate}}
            getOptionValue={this.getOptionValue}
            getOptionLabel={this.getOptionLabel}
            isOptionDisabled={this.isOptionDisabled}
            onChange={this.handleChange}
            menuPlacement="top"
            isSearchable={false}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                'primary': '#428D73FF',
                'primary25': '#97BFAEFF',
                'primary50': '#97BFAEFF',
                'primary75': '#97BFAEFF',
              }
            })}
          />
          <Link
            id="buttonLink"
            to={{
              pathname: "/retreats/booking/1",
              state: {
                object: this.props.object,
                retreat: this.state.retreat,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
              }}}
            style={{ textDecoration: 'none', color: 'black'}}
          >
            <button
              type="submit"
              onClick={this.book}
              disabled={disabled}
            >
              {!(disabled) &&
                "Book"
              }
              {disabled && this.props.object.dates.length === 1 &&
                "Currently Unavailable"
              }
            </button>
          </Link>
        </div>
      </div>
    )
  }
}
