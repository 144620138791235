import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Image, Transformation} from 'cloudinary-react';

class Vibe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
    };
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
      setTimeout(() => {
           this.setState({didMount: true})
       }, 0)
  }

  render() {
    return (
      <div id="signup_vibe" className={`signup_desktop_content`}>
        <h1>Tell us your vibe.</h1>
        <div className="vibeContainer">
          {vibes.map((eachInspiration) => {
            return (
              <div
                onClick={this.props.selected.bind(this, eachInspiration.key)}
                className={this.props.vibe === eachInspiration.key ? "vibeCardSelected" : "vibeCard"}
                key={eachInspiration.id}
                value={eachInspiration.id}
              >
                <Image cloudName="scenerie" publicId={eachInspiration.img} alt={eachInspiration.description}>
                  <Transformation dpr="auto" width="auto:100:500" fetchFormat="auto" crop="fit"/>
                </Image>
                <div className="vibeCard_description">
                  <p>{eachInspiration.description}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <button className={this.props.vibe ? null : "hide"} onClick={this.props.next}>Next</button>
        </div>
      </div>
    )
  }
}

export default withRouter(Vibe);



export const vibes = [
  {
    id: 1,
    key: "relax",
    description: "Taking it slow",
    img: "signup/photo-1505236732171-72a5b19c4981_ffwxdo"
  },
  {
    id: 2,
    key: "someactivity",
    description: "Some relaxing with some activities",
    img: "signup/photo-1520869309377-88c9274a27c2_lqhzq3"
  },
  {
    id: 3,
    key: "active",
    description: "Looking to be active",
    img: "signup/photo-1440186347098-386b7459ad6b_nrvuda"
  },
]
