import React, { Component } from "react";

export default class Hiw extends Component {
  render() {
    return (
      <div id="signup_hiw" className="signup_desktop_content">
        <div className="signup_hiw_content">
          {intro.map((eachIntro, index) => {
            return (
              <div className="hiwBox">
                {index === 2 ? (
                  <div className="hiwBox_img">
                    <img
                      className="img_last_child"
                      src={eachIntro.img}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="hiwBox_img">
                    <img src={eachIntro.img} alt="" />
                  </div>
                )}
                <div className="hiwBox_underImg">
                  <h1 className="hiwBox_number">{eachIntro.id}</h1>
                  <div className="hiwBox_content">
                    <p>{eachIntro.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const intro = [
  {
    id: "1.",
    description:
      "Tell us what you are looking for in your next retreat: select your vibe, your favourite activites and possible travel weekends.",
    img: "/img/signup/intro/11.png"
  },
  {
    id: "2.",
    description:
      "We then send you three personalized retreat itineraries every week until you find the right one to book.",
    img: "/img/signup/intro/12.png"
  },
  {
    id: "3.",
    description:
      "During your retreat, we text you every night all the detailed infos for your next day's activities.",
    img: "/img/signup/intro/13.png"
  }
];
