import React, { Component } from 'react';
import {Image, Transformation} from 'cloudinary-react';

export default class Expandable1 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      title: "Full Itinerary",
      titleHighlight1: this.props.object.itinerary.titleHighlight1,
      titleHighlight2: this.props.object.itinerary.titleHighlight2,
      titleHighlight3: this.props.object.itinerary.titleHighlight3,
      highlight1: this.props.object.itinerary.highlight1,
      highlight2: this.props.object.itinerary.highlight2,
      highlight3: this.props.object.itinerary.highlight3,
      img: this.props.object.itinerary.img,
    };
    this.expand = this.expand.bind(this);
  }

  expand() {
    this.setState(state => ({
      active: !state.active
    }));
  }

  render() {

    return (
      <div id="itinerary" className="desktopWrapper">
        <div className="itinerary_split desktopWrapper">
          <div className="detailsWrapper detailsWrapper2">
            <div className="detailsWrapper_inside">
              <h2>{this.state.titleHighlight1}</h2>
              <div className="detailsWrapper_flex">
                  <ul>
                    {this.state.highlight1.map((eachObject, index) => {
                      return (
                        <li>{eachObject}</li>
                      )
                    })}
                  </ul>
                <Image cloudName="scenerie" clienthints="true" publicId={this.state.img.highlight1} alt="">
                  <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
                </Image>
              </div>
            </div>
          </div>
          <div className="detailsWrapper detailsWrapper2">
            <div className="detailsWrapper_inside">
              <h2>{this.state.titleHighlight2}</h2>
              <div className="detailsWrapper_flex">
                  <ul>
                    {this.state.highlight2.map((eachObject, index) => {
                      return (
                        <li>{eachObject}</li>
                      )
                    })}
                  </ul>
                <Image cloudName="scenerie" clienthints="true" publicId={this.state.img.highlight2} alt="">
                  <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
                </Image>
              </div>
            </div>
          </div>
          <div className="detailsWrapper detailsWrapper2 backgroundGrey">
            <div className="detailsWrapper_inside">
              <h2>{this.state.titleHighlight3}</h2>
              <div className="detailsWrapper_flex">
                  <ul>
                    {this.state.highlight3.map((eachObject, index) => {
                      return (
                        <li>{eachObject}</li>
                      )
                    })}
                  </ul>
                <Image cloudName="scenerie" clienthints="true" publicId={this.state.img.highlight3} alt="">
                  <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
                </Image>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
