import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class MobileHead extends Component {

  constructor(props) {
    super(props);
    this.state = {
      link: this.props.link.split("?")[0],
      search: this.props.link.includes("?") ? this.props.link.split("?")[1] : "",
    };
  }

  //TODO: Get this working for back button from Step 2 to Step 1
  render() {
    console.log(this.state.link)
    return (
      <div id="mobileHead">
        <Link to={{ pathname: this.state.link, search: this.state.search }} style={{ textDecoration: 'none', color: 'black'}}>
          <img src="/img/icons/arrow_grey_left.png" alt="Nav"/>
        </Link>
        <h1>Scenerie</h1>
      </div>
    )
  }
}
