import React, { Component } from 'react';
import axios from "axios";
import MobileHead from '../components/General/MobileHead';
import RetreatCover from '../components/Retreat/RetreatCover';
import Location from '../components/Retreat/Location';
import BookRetreat from '../components/Retreat/BookRetreat';
import AllDetails from '../components/Retreat/AllDetails';
import HowItWorks from '../components/Retreat/HowItWorks';
import Itinerary from '../components/Retreat/Itinerary';

export default class RetreatDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      retreatId: this.props.location.search.split('?id=')[1],
      width: 0,
    };
    window.addEventListener("resize", this.update);
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop();
    axios.get('https://api.scenerie.co/api/retreat' + this.props.location.search)
      .then((result) => this.setState({ object: result.data }));
    this.update();
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  render() {
    if (!this.state.object) {
      return null;
    }
    const lat = this.state.object.property.location.lat;
    const lng = this.state.object.property.location.lng;
    var markers = [
      {
        lat: lat,
        lng: lng,
        src: "/img/icons/icons8-home-24.png",
        alt: "home",
      }
    ]
    this.state.object.mapData.markers.forEach(marker => {
      markers.push({
        lat: marker.lat,
        lng: marker.lng,
        src: marker.icon,
      });
    })
    return (
      <div id="retreatDetails" className="retreatDetails2">
        {(this.state.width < 480) ?  (
          <MobileHead/>
        ) : (
          <h1 className="logo_fullScreen">Scenerie</h1>
        )}
        <div id="retreatDetails_content" className="desktopWrapper">
          <RetreatCover object={this.state.object}/>
          <Itinerary object={this.state.object}/>
          <div className="retreatDetails_split">
            <Location
              center={{lat: lat, lng: lng}}
              zoom={this.state.object.mapData.zoom}
              markers={markers}
            />
            {(this.state.width > 480) ?  (
              <hr/>
            ) : null}
            <AllDetails object = {this.state.object} />
          </div>
          <HowItWorks />
          {/* <Recommendation object={this.state.object} /> */}
        </div>
        <BookRetreat object={this.state.object} retreat={this.state.retreatId} />
      </div>
    )
  }
}
