import React, { Component } from 'react'

export default class SignIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
    this.setState({isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div></div>;
    }
    return (
      <div className="signinContainer signup_desktop_content">
        <h1>Ready to escape the city?</h1>
        <div>
          <p>Join our community and get ready-to-book <strong>Scenerie Retreats</strong> straight to your inbox - every Wednesday at noon.</p>
          <form action="" method="post" className="form" >
            <input 
              type="text" 
              placeholder="Your first name" 
              value={this.props.name} 
              name="name" 
              onChange={this.props.handleChange}/>
            <input
              type="text"
              value={this.props.zip}
              name="zip"
              onChange={this.props.handleChange}
              placeholder="Your ZIP Code"
            />
            <button id="submitEmail" type="submit" onClick={this.props.signup} ref={this.props.childRef}>
              Join
            </button>
          </form>
          <p>{this.props.message}</p>
        </div>
      </div>
    );
  }
}

