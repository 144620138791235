import React, { Component } from 'react';

export default class Expandable3 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      title: "Extra Information",
      checkin: this.props.object.property.checkin,
      checkout: this.props.object.property.checkout,
    };
  }


  render() {

    return (
      <div id="expandable">
        <div className="expandable_collapsed">
          <div className="expandable2_collapsed">
            <img src="/img/detailView/icons/checkin.png" alt="."/>
            <p>Check-in: {this.state.checkin}</p>
          </div>
          <div className="expandable2_collapsed">
            <img src="/img/detailView/icons/checkout.png" alt="."/>
            <p>Checkout: {this.state.checkout}</p>
          </div>
        </div>
      </div>
    )
  }
}
