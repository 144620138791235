import React, { Component } from "react";


const attributesMutable = ["title", "category", "description", "tags"];
const attributesFixed = ["saves", "lat", "lng"];

export default class ActivityData extends Component {

  render() {
    const activity = this.props.selected
    return (
      <div id="activityData">
        {attributesMutable
          .map(a => activity[a])
          .map((value, i) => (
          <div className="element" key={attributesMutable[i]}>
            <label>
              {attributesMutable[i]}
              {!(attributesMutable[i] === "description") &&
                <input id={attributesMutable[i]} type="text" name={attributesMutable[i]} value={value} />
              }
              {(attributesMutable[i] === "description") &&
                <textarea rows="3" id={attributesMutable[i]} name={attributesMutable[i]} value={value} />
              }
            </label>
          </div>
        ))}
        {attributesFixed
          .map(a => activity[a])
          .map((value, i) => (
          <div className="element" key={attributesMutable[i]}>
            <label>
              {attributesFixed[i]}
              <input id={attributesFixed[i]} type="text" name={attributesFixed[i]} value={value} disabled />
            </label>
          </div>
        ))}
        <div className="element" key="link">
          <p>Link:</p>
          <a target="_blank" rel="noopener noreferrer" href={activity.link}>
            {activity.link}
          </a>
        </div>
        <button className="saveChanges" type="submit">
          Save
        </button>
      </div>
    )
  };
}
