import React, { Component } from 'react';
import Helmet from 'react-helmet';
import DayPicker from 'react-day-picker';
import { withRouter } from "react-router-dom";


class Calendar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
    };
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
      setTimeout(() => {
           this.setState({didMount: true})
       }, 0)
  }

  render() {
    function sunday(day) {
      return day.getDay() === 0;
    };

    return (
      <div id="signup_calendar" className={`signup_desktop_content`}>
        <h1>Already have dates in mind?</h1>
        <div className="calendarContainer">
          <p><strong>Optional:</strong><br />Pick up to three weekends.</p>
          <div className="selectWeekends">
            <DayPicker
              selectedDays={[].concat(...this.props.selectedWeekends)}
              onDayClick={this.props.handleDayClick}
              firstDayOfWeek={1}
              disabledDays={[{before: new Date()}, { daysOfWeek: [1, 2, 3, 4] }]}
              fromMonth={new Date()}
              toMonth={new Date(new Date().getFullYear(), new Date().getMonth() + 3)}
              modifiers={{sunday}}
            />
            <Helmet>
              <style>{`
                .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--sunday) {
                  position: relative;
                  background-color: #235745ff;
                  color: #F0F8FF;
                }
                .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                  position: relative;
                  background-color: #81a195ff;
                  color: #F0F8FF;
                }

                .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--sunday):hover {
                  background-color: #235745ff;
                }
                .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                  background-color: #81a195ff;
                }

                .DayPicker-Day--sunday {
                  background-color: transparent;
                }
                .DayPicker-Day--sunday:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                  color: #000000 !important;
                }

                .DayPicker:not(.DayPicker--interactionDisabled)
                  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
                  background-color: rgba(109, 161, 213, 0.1);
                }
              `}</style>
            </Helmet>
          </div>
        </div>
        <div>
          <button onClick={this.props.next}>Next</button>
        </div>
      </div>
    );
  }
}

export default withRouter(Calendar);
