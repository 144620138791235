import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footerFlex">
          <p className="scenerieFooter">Scenerie</p>
          <div className="madeWithLove">
          <p> - Made with </p><img src="/img/icons/heart.png" alt="love"></img><p> in SF & LA</p>
          </div>
        </div>
        <div className="footerFlex">
          <p>sayhi@scenerie.co</p>
          <a href="https://www.instagram.com/scenerieretreats"><img className="igIcon" src="/img/icons/insta.png" alt="@scenerieretreats"/></a>
        </div>
        <div className="footerTerms">
          <p>
            <a href="/termsofservice">Terms of Service</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="/privacypolicy">Privacy Policy</a>
          </p>
        </div>
      </div>
    )
  }
}
