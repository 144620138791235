import React, { Component } from 'react';

class Header extends Component {

  render() {
    return (
    <div className= {this.props.header} style={{backgroundColor: this.props.opacity, boxShadow: this.props.boxShadow}} >
      <h1 style={{color: `${this.props.color}`}}>Scenerie</h1>
      {/* <Link to={{ pathname: '/howitworks' }} style={{ textDecoration: 'none', color: 'black'}}>
        <p style={{color: `${this.props.color}`}}>How it works</p>
      </ Link>
    */}
    </div>
    );
  }
}

export default Header;
