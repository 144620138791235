import React, { Component } from 'react';


export default class Tagging extends Component {

  constructor(props) {
    super(props);
    this.state = {
      object: [
        {
          id: 1,
          title: "Kitchen",
          description: "A clean kitchen with plenty of space.",
          img: "https://autocamp.com/wp-content/uploads/2019/07/geremia-acy-324x.jpg",
          tags: ['kitchen', 'unique'],
        },
        {
          id: 2,
          title: "Bed",
          description: "A big bed in tiny space.",
          img: "https://autocamp.com/wp-content/uploads/2018/12/H9I9752-1400x933.jpg",
          tags: ['bedroom', 'unique'],
        },
        {
          id: 3,
          title: "Main Area",
          description: "A lot of space for two people.",
          img: 'https://autocamp.com/wp-content/uploads/2018/12/H9I9767-1400x933.jpg',
          tags: ['livingroom'],
        },
      ]
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };


  render() {

    return (
      <div id="admin_tagging">
        <div className="admin_tagging_goBack" >
          <img src="/img/icons/arrow_grey_left.png" alt="Back"/>
          <p>Back</p>
        </div>
        <div className="admin_tagging_content">
          <div className="admin_tagging_content_arrow">
            <img src="/img/icons/arrow_grey_left.png" alt=""/>
          </div>
          <div className="admin_tagging_content_details">
            {this.state.object.map((eachObject, index) => {
          return (
            <div className="admin_tagging_content_details_box" key={eachObject.id} value={eachObject.id}>
              <div className="admin_tagging_content_details_box_img">
                <img src={eachObject.img} alt=""/>
              </div>
              <h4>{eachObject.description}</h4> 
              <h3>{eachObject.title}</h3>  
              <div className="admin_tagging_content_details_box_tags">
              <p><strong>Tags: </strong> </p> 
              {eachObject.tags.map((eachTag,index) => {
                return  (
                    <p>{eachTag}, </p>
                )
              })}
              </div>
              <div className="admin_tagging_content_details_box_tags">
                <form action="post">
                  <input type="text" onChange={this.handleChange} name="newTag" value={eachObject.tags[eachObject.tags.length-1]} placeholder="New Tag" />
                </form>
              </div>
            </div>
            )
           })}
        </div>
          <div className="admin_tagging_content_arrow">
            <img src="/img/icons/arrow_grey_right.png" alt=""/>
          </div>
        </div>
        <ul>
            <li className="is-active"></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        <div>

        </div>
      </div>
    )
  }
}
