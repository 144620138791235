import React, { Component } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import Vibe from '../components/Signup/Vibe';
import TravelMates from '../components/Signup/TravelMates';
import Prices from '../components/Signup/Prices';
import Calendar from '../components/Signup/Calendar';
import Update from '../components/Signup/Update';
import AllSetPreferences from '../components/Signup/AllSetPreferences';

import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function getWeekend(date) {
  if (date.getDay() === 5) {
    return [
      date,
      moment(date)
        .add(1, 'days')
        .toDate(),
      moment(date)
        .add(2, 'days')
        .toDate(),
    ]
  } else if (date.getDay() === 6) {
    return [
      moment(date)
        .add(-1, 'days')
        .toDate(),
      date,
      moment(date)
        .add(1, 'days')
        .toDate(),
    ];
  } else if (date.getDay() === 0) {
    return [
      moment(date)
        .add(-2, 'days')
        .toDate(),
      moment(date)
        .add(-1, 'days')
        .toDate(),
      date,
    ];
  } else {
    return;
  }
}

class UpdatePreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: Vibe,
      didMount: false,
      didMountBack: false,
      willUnmount: false,
      slideOut: false,
      slideBack: false,
      slideIn: true,
      vibe: '',
      travelMates: '',
      priceRange: [100, 400],
      selectedWeekends: [],
      zip: '',
      message: '',
      name: '',
      id: this.props.location.search.split('?id=')[1],
    };
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.GA1 = this.GA1.bind(this);
    this.GA2 = this.GA2.bind(this);
    this.GA3 = this.GA3.bind(this);
    this.GA4 = this.GA4.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.childRef = React.createRef();
  }

  componentDidMount () {
    axios.get('https://api.scenerie.co/api/preferences' + this.props.location.search)
      .then((result) => {
          var now = new Date();
          var selectedWeekends = result.data.selectedWeekends.map(
              dates => dates.map(d => moment(d).toDate())
          );
          selectedWeekends = selectedWeekends.filter(
              dates => dates.every(d => d.getTime() > now.getTime())
          );
          this.setState({
              vibe: result.data.vibe,
              travelMates: result.data.travelMates,
              priceRange: result.data.priceRange,
              zip: result.data.zip,
              selectedWeekends: selectedWeekends,
              name: result.data.name,
          });
      });
  }

  async next() {
    setTimeout(() => {
      this.setState({ slideIn: false, didMount: false, didMountBack: false });
    }, 0);

    setTimeout(() => {
      this.setState({ slideOut: true });
    }, 100);

    setTimeout(() => {
      if (this.state.active === Vibe) {
        this.GA1();
        this.setState({ active: TravelMates, slideOut: false, didMount: true });
      } else if (this.state.active === TravelMates) {
        this.GA2();
        this.setState({ active: Prices, slideOut: false, didMount: true });
      } else if (this.state.active === Prices) {
        this.GA3();
        this.setState({ active: Calendar, slideOut: false, didMount: true });
      } else if (this.state.active === Calendar) {
        this.GA4();
        this.setState({ active: Update, slideOut: false, didMount: true });
      } else if (this.state.active === Update) {
        this.setState({ active: AllSetPreferences, slideOut: false, didMount: true });
      }
    }, 850);

    setTimeout(() => {
      this.setState({ slideIn: true });
    }, 1000);
  }

  async back() {
    setTimeout(() => {
      this.setState({ slideIn: false, didMount: false, didMountBack: false });
    }, 0);

    setTimeout(() => {
      this.setState({ slideBack: true });
    }, 0);

    setTimeout(() => {
      if (this.state.active === TravelMates) {
        this.GA2();
        this.setState({
          active: Vibe,
          slideBack: false,
          didMountBack: true,
        });
      } else if (this.state.active === Prices) {
        this.setState({
          active: TravelMates,
          slideBack: false,
          didMountBack: true,
        });
      } else if (this.state.active === Calendar) {
        this.GA3();
        this.setState({ 
          active: Prices, 
          slideBack: false, 
          didMountBack: true
        });
      } else if (this.state.active === Update) {
        this.GA4();
        this.setState({
          active: Calendar,
          slideBack: false,
          didMountBack: true,
        });
      }
    }, 850);

    setTimeout(() => {
      this.setState({ slideIn: true });
    }, 1000);
  }

  selected = eachInspiration => {
    if (this.state.active === Vibe) {
      this.setState({ vibe: eachInspiration });
    }
    if (this.state.active === TravelMates) {
      this.setState({ travelMates: eachInspiration });
    }
  };

  handlePrice = (event, newPriceRange) => {
    this.setState({ priceRange: newPriceRange });
  };

  handleDayClick(day, { disabled }) {
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    const { selectedWeekends } = this.state;
    var idx;
    for (let i = 0; i < selectedWeekends.length; i++) {
      if (
        DateUtils.isSameDay(selectedWeekends[i][0], day) ||
        DateUtils.isSameDay(selectedWeekends[i][1], day) ||
        DateUtils.isSameDay(selectedWeekends[i][2], day)
      ) {
        idx = i;
        break;
      }
    }
    if (idx != null) {
      selectedWeekends.splice(idx, 1);
    } else if (selectedWeekends.length < 3) {
      selectedWeekends.push(getWeekend(day));
    }
    this.setState({ selectedWeekends });
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  updatePreferences = e => {
    e.preventDefault();
    this.childRef.current.disabled = true;
    if (!this.state.zip) {
      this.setState({
        message: 'Please fill in your ZIP code.',
      });
      this.childRef.current.disabled = false;
      return
    }
    const postData = Object.assign({}, this.state);
    axios.post('https://api.scenerie.co/api/preferences', postData)
      .then(result => {
        this.next();
        ReactGA.event({
          category: 'Update Preferences',
          action: 'User submitted preferences',
        });
      })
      .catch(err => {
        ReactGA.event({
          category: 'Update Preferences',
          action: 'Error: User tried to update preferences, request failed.',
        });
        // TODO: Handle different error cases.
        this.setState({
          message: 'Something went wrong. Please try again or reach out to us if the error persists.',
        });
        this.childRef.current.disabled = false;
      });
  };

  GA1() {
    ReactGA.event({
      category: 'Update Preferences',
      action: 'User selected vibe',
      label: this.state.vibe,
    });
  }

  GA2() {
    ReactGA.event({
      category: 'Update Preferences',
      action: 'User selected travel mates',
      label: this.state.travelMates,
    });
  }

  GA3() {
    ReactGA.event({
      category: 'Update Preferences',
      action: 'User selected budget',
      label: this.state.priceRange.join(' '),
    });
  }

  GA4() {
    const dates = this.state.selectedWeekends.join('');
    ReactGA.event({
      category: 'Update Preferences',
      action: 'User selected available dates',
      label: dates,
    });
  }

  render() {
    const { slideOut, slideBack, slideIn, didMount, didMountBack } = this.state;
    return (
      <div id="signup" className="signupFlow">
        <div className="signupFlow_header">
          <Link
            to={{ pathname: '/' }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <h1>Scenerie</h1>
          </Link>
          {this.state.active !== AllSetPreferences && this.state.active !== Vibe &&
            <img src="/img/icons/arrow_up.png" className="back" onClick={this.back} alt="back" />
          }
        </div>
        <div className="signupFlow_container">
          <div
            className={`${didMount && 'fade-in'} ${didMountBack && 'fade-back-in'} ${slideOut &&
              'fade-out'} ${slideBack && 'fade-back'}  ${slideIn && 'visible'}`}
          >
            <this.state.active
              childRef={this.childRef}
              next={this.next}
              selected={this.selected}
              handlePrice={this.handlePrice}
              handleDayClick={this.handleDayClick}
              handleChange={this.handleChange}
              updatePreferences={this.updatePreferences}
              vibe={this.state.vibe}
              travelMates={this.state.travelMates}
              priceRange={this.state.priceRange}
              selectedWeekends={this.state.selectedWeekends}
              name={this.state.name}
              email={this.state.email}
              zip={this.state.zip}
              message={this.state.message}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdatePreferences);