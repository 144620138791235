import React, { Component } from "react";
import {Image, Transformation} from 'cloudinary-react';

export default class ActivityImages extends Component {

  render() {
    return (
      <div id="propertyImages">
        <Image
          cloudName="scenerie"
          clienthints="true"
          publicId={this.props.selected.img_filename}
          alt={this.props.selected.title}
        >
          <Transformation dpr="auto" width="auto:100:800" fetchFormat="auto" crop="fit" />
        </Image>
      </div>
    )
  }
}
