import React, { Component } from 'react'

export default class Update extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
    this.setState({isLoading: false});
  }

  render() {
    if (this.state.isLoading) {
      return <div></div>;
    }
    return (
      <div className="signinContainer signup_desktop_content">
        <h1>Lastly, did you move?</h1>
        <div>
          <p> Please keep your ZIP code updated.</p>
          <form action="" method="post" className="form" >
            <input
              type="text"
              value={this.props.zip}
              name="zip"
              onChange={this.props.handleChange}
              placeholder="Your ZIP Code"
            />
            <button id="submitEmail" type="submit" onClick={this.props.updatePreferences} ref={this.props.childRef}>
              Submit
            </button>
          </form>
          <p>{this.props.message}</p>
        </div>
      </div>
    );
  }
}

