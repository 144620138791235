// Snazzy Maps
export const mapStyles = [
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [{ "visibility": "on" }]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels",
    "stylers": [
      { "visibility": "simplified" },
      { "saturation": "0" },
      { "gamma": "1.00" },
      { "color": "#939393" }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels.text",
    "stylers": [{ "color": "#707070" }]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels.text.fill",
    "stylers": [
      { "visibility": "on" },
      { "color": "#707070" }
    ]
  },
  {
    "featureType": "poi.attraction",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "visibility": "on" },
      { "color": "#fefefe" },
      { "weight": "2.00" }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels",
    "stylers": [{ "color": "#939393" }]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels.text",
    "stylers": [{ "color": "#707070" }]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels.text.fill",
    "stylers": [
      { "color": "#707070" },
      { "visibility": "on" }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "color": "#ffffff" },
      { "visibility": "on" },
      { "weight": "2.00" }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [{ "color": "#c2e5a7" }]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
      { "saturation": "0" },
      { "lightness": "0" },
      { "gamma": "1.00" },
      { "weight": "1.00" },
      { "color": "#669633" }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [{ "color": "#669633" }]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      { "color": "#ffffff" },
      { "weight": "2.00" }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text",
    "stylers": [{ "color": "#545454" }]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      { "saturation": "-87" },
      { "lightness": "-40" },
      { "color": "#ffffff" }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.fill",
    "stylers": [
      { "color": "#f0f0f0" },
      { "saturation": "-22" },
      { "lightness": "-16" }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "labels.icon",
    "stylers": [{ "visibility": "on" }]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      { "saturation": "-52" },
      { "lightness": "-16" },
      { "color": "#aedcff" }
    ]
  }
]
