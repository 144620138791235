import React, { Component } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';


export default class DateRange extends Component {

  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleMaxGuestsChange = this.handleMaxGuestsChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      max_guests: "0",
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined }, () => {
      this.from.getInput().value = null;
      this.to.getInput().value = null;
    });
  }

  handleMaxGuestsChange(event) {
    this.setState({ max_guests: event.target.value });
  }

  handleButtonClick() {
    if (this.state.from && this.state.to) {
      this.props.onClick(
        JSON.stringify(this.state.from).slice(1, 11),
        JSON.stringify(this.state.to).slice(1, 11),
        this.state.max_guests,
      );
    } else if (this.state.max_guests !== "0") {
      this.props.onClick(
        null,
        null,
        this.state.max_guests,
      );
    }
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo">
        <DayPickerInput
          ref={el => (this.from = el)}
          value={from}
          placeholder="From"
          format="LL"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
        <button className="clearButton" onClick={this.handleResetClick}>
          Clear
        </button>
        <label>Max Guests:
          <select value={this.state.max_guests} onChange={this.handleMaxGuestsChange}>
            <option value="0">0 guests</option>
            <option value="1">1 guest</option>
            <option value="2">2 guests</option>
            <option value="3">3 guests</option>
            <option value="4">4 guests</option>
            <option value="5">5 guests</option>
            <option value="6">6 guests</option>
            <option value="7">7 guests</option>
            <option value="8">8 guests</option>
            <option value="9">9 guests</option>
            <option value="10">10 guests</option>
          </select>
        </label>
        <button
          className="saveChanges"
          onClick={() => this.handleButtonClick()}
        >
          Search
        </button>
        <Helmet>
          <style>{`
  input {
    font-size: 16px;
    padding: 10px 10px;
  }
  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
  }
  .InputFromTo .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .InputFromTo .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 550px;
  }
  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -198px;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}
