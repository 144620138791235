import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react';
import { mapStyles } from './MapStyles';

const Marker = ({ src, alt }) => (
  <div className="marker">
    <img src={src} alt={alt} />
  </div>
);

export default class Location extends Component {

  createMapOptions = (maps) => {
    return {styles: mapStyles}
  }

  render() {
    return (
      <div id="location">
        <h2>Retreat location</h2>
        <div id="locationMap">
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAxXN7DutALb8b5podO7BQMqx042SdZfNE", language: "en" }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            options={this.createMapOptions}
          >
            {this.props.markers.map((marker, i) =>
              <Marker
                key={i}
                lat={marker.lat}
                lng={marker.lng}
                src={marker.src}
                alt={marker.alt}
              />
            )}
          </GoogleMapReact>
        </div>
      </div>
    )
  }
}
