import React, { Component } from 'react';
import Header from '../General/Header';
import Hiw from "./Hiw";
import { withRouter } from "react-router-dom";
import Footer from "../General/Footer";

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      header: 'coverContent_head',
      opacity: 'rgb(0,0,0,0)',
      color: 'rgb(0,0,0)',
      boxShadow: '0 0 10px 0 rgba(0,0,0,.16)',
    };
    window.addEventListener("resize", this.update);
    this.update = this.update.bind(this);
    this.intro = React.createRef()
  }

  startAtTop = () => window.scrollTo(0, 0);
  jumpToIntro = () => window.scrollTo(0, this.intro.current.offsetTop - 150)

  componentDidMount() {

    this.startAtTop();
    this.setState({
      width: window.innerWidth
    });
    this.setState(this.props.location.state);
    this.setState({color: `rgb(255,255,255)`, boxShadow: '0 0 10px 0 rgba(0,0,0,.16)'})
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = e => {
    let scrollTop = e.target.scrollingElement.scrollTop,
    opacity = scrollTop/400;

    let colorCode = 255-(opacity*531);

    if (opacity > 0.48) {
      this.setState({color: '#1d1d1d', boxShadow: '0 0 10px 0 rgba(0,0,0,.16)'})
    } else {
      console.log(colorCode)
      this.setState({color: `rgb(${colorCode},${colorCode},${colorCode})`, boxShadow: 'none'})
    } 

    this.setState({
      opacity: `rgb(255,255,255,${opacity})`
    }); 
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  jump() {}

  render() {
    return (
      <div id="signup">
          <div className="signup_desktop">
            <Header header={this.state.header} opacity={this.state.opacity} color={this.state.color} boxShadow={this.state.boxShadow} />
            <div className="signup_desktop_allContent">
            <div className="signup_desktop_outer">
              <Hiw/>
            </div>
            </div>
            <Footer />
          </div>
      </div>
    );
  }
}

export default withRouter(HowItWorks);
