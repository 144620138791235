import React, { Component } from 'react';
import axios from 'axios';
import MobileHead from '../components/General/MobileHead';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {Image, Transformation} from 'cloudinary-react';


class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uId: this.props.location.search.split('?id=')[1],
      width: 0,
    };
  }

  componentDidMount () {
    this.startAtTop();
    this.updateFavs();
    this.update();
  }

  startAtTop = () => window.scrollTo(0, 0)

  updateFavs = () => {
    axios.get('https://api.scenerie.co/api/favorites' + this.props.location.search)
      .then((result) => this.setState({ favorites: result.data }));
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  unfavorite = (mId) => {
    axios.delete('https://api.scenerie.co/api/favorites',
      {data: {uId: this.state.uId, mId: mId}},
    ).then((result) => {
      window.location.reload(false);
    });
  };

  render() {
    if (!this.state.favorites) {
      return null;
    }
    return (
      <div id="favorites" className="favorites">
        {(this.state.width < 480) ?  (
          <MobileHead linkPath="retreats" linkSearch={this.props.location.search}/>
        ) : (
          <Link
            to={{
              pathname: '/retreats',
              search: this.props.location.search }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <h1 className="logo_fullScreen">Scenerie</h1>
          </Link>
        )}
        <div id="favorites_content" className="desktopWrapper">
          <div className="title">
            <h1>Your Favorites</h1>
          </div>
          <div className="favsCards">
            {this.state.favorites.map((f) => {
              return (
                <div className="favsCard">
                  <Link
                    className="cardLink"
                    to={{ pathname: "/retreats", search: "?id=" + this.state.uId + "&m=" + f.mId }}
                  >
                    <Link
                      className="cardLink"
                      to={{ pathname: "/favorites", search: "?id=" + this.state.uId }}
                      onClick={() => this.unfavorite(f.mId)}
                    >
                      <img className="heartImage" src="/img/icons/heart-white-48.png" alt="heart"/>
                    </Link>
                    <div className="favsCardImages">
                      <Image cloudName="scenerie" publicId={f.titleImg} alt="reatreat image">
                        <Transformation dpr="auto" width="auto:100:500" fetchFormat="auto" crop="fill"/>
                      </Image>
                      <div className="favsCardImagesVertical">
                        <div className="topImage">
                          <Image cloudName="scenerie" publicId={f.propertyImages[0]} alt="property image 1">
                            <Transformation dpr="auto" width="auto:100:350" fetchFormat="auto" crop="fill"/>
                          </Image>
                        </div>
                        <div className="bottomImage">
                          <Image cloudName="scenerie" publicId={f.propertyImages[1]} alt="property image 2">
                            <Transformation dpr="auto" width="auto:100:350" fetchFormat="auto" crop="fill"/>
                          </Image>
                        </div>
                      </div>
                    </div>
                    <div className="favsCardContent">
                      <div className="favsCardDetails">
                        <p>{f.distance} drive</p>
                        <p> · </p>
                        <p>{f.guests} people</p>
                      </div>
                      <h1>{f.title}</h1> 
                      <div className="favsCardPrice">
                        ${f.price} per night
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Favorites);