import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const marks = [
  { value: 0, label: "$0" },
  { value: 100, label: "$100" },
  { value: 200, label: "$200" },
  { value: 300, label: "$300" },
  { value: 400, label: "$400" },
  { value: 500, label: "$500+" }
];

function valuetext(value) {
  return `$${value}`;
}

const ColoredSlider = withStyles({ root: { color: "#235745ff" } })(Slider);

class Prices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didMount: false,
    };
  }

  startAtTop = () => window.scrollTo(0, 0);

  componentDidMount() {
    this.startAtTop();
    setTimeout(() => {
      this.setState({ didMount: true });
    }, 0);
  }

  render() {
    return (
      <div id="signup_prices" className={`signup_desktop_content`}>
        <h1>What is your budget?</h1>
        <div className="pricesSlider">
          <p>per night per person</p>
          <ColoredSlider
            value={this.props.priceRange}
            onChange={this.props.handlePrice}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            step={100}
            marks={marks}
            min={0}
            max={500}
          />
        </div>
        <div>
          <button onClick={this.props.next}>Next</button>
        </div>
      </div>
    );
  }
}

export default withRouter(Prices);
