import React, { Component } from "react";


const attributesMutable = ["title", "description"];
const attributesFixed = ["lat", "lng"];
const attributesRoomFixed = ["bedrooms", "max_guests", "bathrooms", "price_per_night"];

export default class PropertyData extends Component {

  render() {
    const property = this.props.selected
    return (
      <div id="propertyData">
        {attributesMutable
          .map(a => property[a])
          .map((value, i) => (
          <div className="element" key={attributesMutable[i]}>
            <label>
              {attributesMutable[i]}
              {!(attributesMutable[i] === "description") &&
                <input id={attributesMutable[i]} type="text" name={attributesMutable[i]} value={value} />
              }
              {(attributesMutable[i] === "description") &&
                <textarea rows="3" id={attributesMutable[i]} name={attributesMutable[i]} value={value} />
              }
            </label>
          </div>
        ))}
        {(property.rooms.length > 0) &&
          attributesRoomFixed
            .map(a => property.rooms[0][a])
            .map((value, i) => (
            <div className="element" key={attributesMutable[i]}>
              <label>
                {attributesRoomFixed[i]}
                <input id={attributesRoomFixed[i]} type="text" name={attributesRoomFixed[i]} value={value} disabled />
              </label>
            </div>
          ))
        }
        {attributesFixed
          .map(a => property[a])
          .map((value, i) => (
          <div className="element" key={attributesMutable[i]}>
            <label>
              {attributesFixed[i]}
              <input id={attributesFixed[i]} type="text" name={attributesFixed[i]} value={value} disabled />
            </label>
          </div>
        ))}
        {(property.rooms.length > 0) &&
          <div className="element" key="link">
            <p>Link:</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={property.rooms[0].link_airbnb || property.rooms[0].link_vrbo || property.rooms[0].link_booking}
            >
              {property.rooms[0].link_airbnb || property.rooms[0].link_vrbo || property.rooms[0].link_booking}
            </a>
          </div>
        }
        <button className="saveChanges" type="submit">
          Save
        </button>
      </div>
    )
  };
}
