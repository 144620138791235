import React, { useEffect, useRef } from "react";

export default function Video() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const videoSource = "https://durwrgi78mpyg.cloudfront.net/videos/all_together";

  return (
      <video muted playsInline ref={videoEl} loop alt="CoverVideo" id="coverVideo" poster={videoSource + "-poster-00001.jpg"}>
          <source src={videoSource + "-transcode.mp4"} type="video/mp4" />
          <source src={videoSource + "-transcode.webm"} type="video/webm" />
      </video>
  );
}
