import React, { Component } from 'react'
import { Link } from 'react-router-dom';


export default class MobileHead extends Component {
  render() {
    return (
      <div id="mobileHead">
        {this.props.linkPath ? (
          <Link
            to={{
              pathname: this.props.linkPath,
              search: this.props.linkSearch,
            }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <h1>Scenerie</h1>
          </Link>
        ) : (
          <h1>Scenerie</h1>
        )}
      </div>
    )
  }
}