import React, { Component } from "react";
import PropertyData from './PropertyData';
import PropertyImages from './PropertyImages';
import ActivityData from './ActivityData';
import ActivityImages from './ActivityImages';

export default class SideBar extends Component {

  render() {
    if (!this.props.selected) {
      return null;
    }
    return (
      <div id="sideBar">
        <form action="" method="post" className="form" >
          {this.props.selectedType === "property" &&
            <React.Fragment>
              <PropertyImages selected={this.props.selected.images} />
              <PropertyData selected={this.props.selected} />
            </React.Fragment>
          }
          {this.props.selectedType === "activity" &&
            <React.Fragment>
              <ActivityImages selected={this.props.selected} />
              <ActivityData selected={this.props.selected} />
            </React.Fragment>
          }
        </form>
      </div>
    )
  };
}
