// Mostly based on: https://github.com/stripe/react-stripe-js/blob/master/examples/hooks/1-Card-Detailed.js
import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {FormField, CardField, SubmitButton, ErrorMessage} from './CheckoutSections';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    phone: '',
    terms: '',
  });

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingDetails.name,
          email: billingDetails.email.toLowerCase(),
          phone: billingDetails.phone,
        },
      },
    });

    setProcessing(false);

    if (result.error) {
      // TODO: Better instrumentation, e.g. send to slack
      setError(result.error);
    } else {
      if (result.paymentIntent.status === 'requires_capture') {
        setSuccessful(true);
      }
    }
  };

  // TODO: Add lock icon to button
  // TODO: Make sure that back button doesn't work, i.e. one user can only pay once
  return successful ? (
    <Redirect to="/retreats/booking/3" />
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup FormGroup1">
        <FormField
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({...billingDetails, name: e.target.value});
          }}
        />
        <FormField
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({...billingDetails, email: e.target.value});
          }}
        />
        <FormField
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel-national"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({...billingDetails, phone: e.target.value});
          }}
        />
      </fieldset>
      <fieldset className="FormGroup FormGroup2">
        <label className="creditCard">Credit Card</label>
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      <fieldset className="FormGroup FormGroup3">
        <FormField
          type="checkbox"
          id="terms"
          placeholder=""
          required
          autoComplete="off"
          value={billingDetails.terms}
          onChange={(e) => {
            setBillingDetails({...billingDetails, terms: e.target.value});
          }}
        />
        <p>I agree to the <a href="/termsofservice" target="_blank">terms of service</a></p>
      </fieldset>
      <div className="bookingSummary_button">
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <SubmitButton processing={processing} error={error} disabled={!stripe}>
          Book Now
        </SubmitButton>
      </div>
    </form>
  );
}
