import React, { Component } from 'react';
import Map from './Map';
import SideBar from './SideBar';
import DateRange from './DateRange';
import axios from "axios";


export default class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      propertiesHidden: false,
      activities: [],
      activitiesHidden: false,
      selected: null,
      selectedType: null,
    };
  }

  componentDidMount() {
    axios.get('https://api.scenerie.co/api/properties')
      .then(
        (result) => {
          this.setState({
            properties: result.data.reduce((o, property) => ({ ...o, [property.id]: property}), {})
          });
        }
      )
    axios.get('https://api.scenerie.co/api/activities')
      .then(
        (result) => {
          this.setState({
            activities: result.data.reduce((o, activity) => ({ ...o, [activity.id]: activity}), {})
          });
        }
      )
  }

  onMapClick(id, type) {
    let selected;
    if (type === "property") {
      selected = this.state.properties[id];
    } else if (type === "activity") {
      selected = this.state.activities[id];
    }
    this.setState({ selected: selected, selectedType: type});
  }

  onSearchClick(from, to, max_guests) {
    var url = 'https://api.scenerie.co/api/properties?';
    var addition = false;
    if (from && to) {
      url = url + 'from=' + from + '&to=' + to;
      addition = true;
    }
    if (max_guests) {
      if (addition) {
        url = url + '&';
      }
      url = url + 'max-guests=' + max_guests;
    }
    axios.get(url)
      .then(
        (result) => {
          this.setState({
            properties: result.data.reduce((o, property) => ({ ...o, [property.id]: property}), {})
          });
        }
      )
  }

  getMarkers() {
    const markers = [];
    if (!this.state.propertiesHidden) {
      markers.push(Object.values(this.state.properties).map((property) => (
        {
          "id": property.id,
          "key": "property" + property.id,
          "type": "property",
          "lat": property.lat,
          "lng": property.lng,
          "src": "/img/icons/icons8-home-24.png",
          "alt": property.title,
        }
      )));
    }
    if (!this.state.activitiesHidden) {
      markers.push(Object.values(this.state.activities).map((activity) => (
        {
          "id": activity.id,
          "key": "acitivity" + activity.id,
          "type": "activity",
          "lat": activity.lat,
          "lng": activity.lng,
          "src": "/img/icons/icons8-walking-24.png",
          "alt": activity.title,
        }
      )));
    }
    return markers.flat();
  }

  render() {
    return (
      <div id="admin">
        <div id="controls">
          <DateRange onClick={(from, to, max_guests) => this.onSearchClick(from, to, max_guests)} />
          {!this.state.propertiesHidden &&
            <button className="clearButton" onClick={() => this.setState({ propertiesHidden: true })}>
              Hide Properties
            </button>
          }
          {this.state.propertiesHidden &&
            <button className="clearButton" onClick={() => this.setState({ propertiesHidden: false })}>
              Show Properties
            </button>
          }
          {!this.state.activitiesHidden &&
            <button className="clearButton" onClick={() => this.setState({ activitiesHidden: true })}>
              Hide Activities
            </button>
          }
          {this.state.activitiesHidden &&
            <button className="clearButton" onClick={() => this.setState({ activitiesHidden: false })}>
              Show Activities
            </button>
          }
        </div>
        <div id="mapContainer">
          <Map
            center={{lat: 37.7749, lng: -122.4194}}
            zoom={11}
            markers={this.getMarkers()}
            onClick={(id, type) => this.onMapClick(id, type)}
          />
          <SideBar selected={this.state.selected} selectedType={this.state.selectedType} />
        </div>
      </div>
    )
  }
}
