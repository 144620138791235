import React, { Component } from 'react'
import {Image, Transformation} from 'cloudinary-react';

export default class Slider2 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  async componentDidMount() {
    const options = {
      root: document.querySelector(".slider2"),
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting === true) {
     /*        console.log(entry.target.id); */
            if (document.querySelector(".is-active")) {
              document.querySelector(".is-active").classList.add("is-inactive");
              document.querySelector(".is-active").classList.remove("is-active");
            }
            document.querySelectorAll(".is-inactive")[entry.target.id].classList.add("is-active");
            document.querySelectorAll(".is-inactive")[entry.target.id].classList.remove("is-inactive");
          }
        })
      },
      options
    );
    document.querySelectorAll('.checkout_slider_content').forEach(element => observer.observe(element));
  }


  render() {
    return (
      <div className="slider2 desktopWrapper">
        <div className="checkout_slider">
          {this.props.object.map((eachObject, index) =>
            <div className="checkout_slider_content" id={index} key={eachObject.id} value={eachObject.id}>
              <Image cloudName="scenerie" clienthints="true" publicId={eachObject.img} alt="">
                <Transformation dpr="auto" width={"auto:100:" + this.props.width} fetchFormat="auto" crop="fit" />
              </Image>
              <h4>{eachObject.title}</h4>
            </div>
          )}
        </div>
        <ul className="slider2_list">
            {this.props.object.map((_) =>
              <li className="is-inactive"></li>
            )}
        </ul>
      </div>
    )
  }
}
