import React, { Component } from "react";
import {Image, Transformation} from 'cloudinary-react';

export default class Intro extends Component {
  render() {
    return (
      <div id="feat1" className="signup_intro" ref={this.props.intro}>
        <div className="signup_intro_content">
          <p>
          <b>Take a break close to home</b><br/><br/>
          Take a break from the indoors and discover all the amazing places around where you live - less than a 3h drive away from home.*
          </p>
          <div className="signup_intro_image">
            <div className="img_overlay">
              <Image
                cloudName="scenerie"
                clienthints="true"
                publicId="homepage/briana-tozour-cab5b2tKeLA-unsplash_myaymu"
                alt="Scenerie Retreat"
              >
                <Transformation dpr="auto" width="auto:100:700" fetchFormat="auto" crop="fit" />
              </Image>
            </div>
          </div>
        </div>
        <p className="signup_intro_content_footnote">
          * currently available in the Bay Area only
        </p>
        <div id="feat2" className="signup_intro_content">
          <div className="signup_intro_image">
            <div className="img_overlay">
              <Image
                cloudName="scenerie"
                clienthints="true"
                publicId="homepage/francisco-delgado-hk35YhSM6vE-unsplash_vwgnjy"
                alt="Scenerie Retreat"
              >
                <Transformation dpr="auto" width="auto:100:700" fetchFormat="auto" crop="fit" />
              </Image>
            </div>
          </div>
          <p>
            <b>Scenerie Retreats come straight to your inbox</b><br/><br/>
            Tired of planning out all the details? We'll pick one of our carefully curated Scenerie Retreats - every week, straight to your inbox, just for you.
          </p>
        </div>
        <div id="feat1" className="signup_intro_content">
          <p>
            <b>Strictly vetted, safe & easy</b><br/><br/>
            Every Scenerie Retreat is carefully vetted to keep you safe and meet our strict quality bar. The best part is that we have everything planned out for you, boutique lodging included - just book & show up. 
          </p>
          <div className="signup_intro_image">
            <div className="img_overlay">
              <Image
                cloudName="scenerie"
                clienthints="true"
                publicId="homepage/finn-YIMXYjQoBBU-unsplash_bw0yto"
                alt="Scenerie Retreat"
              >
                <Transformation dpr="auto" width="auto:100:700" fetchFormat="auto" crop="fit" />
              </Image>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
