import React, { Component } from 'react';
import MobileHead from './MobileHead';
import Slider2 from '../../General/Slider2';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import ReactGA from 'react-ga';
import { getDay, getMonth} from '../DateUtil'
import axios from "axios";


class BookingSummary extends Component {
  constructor(props) {
    super(props);
    var link = "/retreats?id=" + this.props.location.state.retreat;
    var userID = link.split("id=")[1].split("&")[0];
    var obj = this.props.location.state.object;
    this.state = {
      object: obj,
      images: [{img: obj.titleImg}, {img: obj.property.images[0].img}],
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      link: link,
      retreat: this.props.location.state.retreat,
      userID: userID,
      width: 0,
      promoCode: "",
      promoMessage: "",
      promoMessageColor: "",
      promoAmount: 0,
      subtotal: obj.property.price * 2,
      total: obj.property.price * 2,
      buttonProcessing: false,
    };
    this.book = this.book.bind(this);
    window.addEventListener("resize", this.update);
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount () {
    this.startAtTop()
    this.setState({
      width: window.innerWidth
    });
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  book() {
    ReactGA.event({
      category: 'Book',
      action: this.state.retreat + ' jumps to credit card information',
    }); 
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state.promoCode);
    this.setState({ processing: true });
    axios.get('https://api.scenerie.co/api/promotion?code=' + this.state.promoCode + '&id=' + this.state.userID)
    .then(response => {
      if ('error' in response.data) {
        if (response.data['error'] === 'expired') {
          this.setState({
            promoMessage: 'This code has expired.',
            promoMessageColor: '#e56767',
            promoAmount: 0,
            total: this.state.subtotal,
          })
        } else if (response.data['error'] === 'already used') {
          this.setState({
            promoMessage: 'This code has already been used.',
            promoMessageColor: '#e56767',
            promoAmount: 0,
            total: this.state.subtotal,
          })
        } else {
          this.setState({
            promoMessage: 'This code is invalid.',
            promoMessageColor: '#e56767',
            promoAmount: 0,
            total: this.state.subtotal,
          })
        }
      } else {
        var subtotal = this.state.subtotal;
        this.setState({
          promoMessage: response.data.amount + '% off',
          promoMessageColor: '#235745',
          promoAmount: response.data.amount,
          total: (Math.round((subtotal * ((100 - response.data.amount) / 100)) * 100) / 100).toFixed(2),
        })
      }
    })
    .catch(err => {
        this.setState({
          promoMessage: 'This code is invalid.',
          promoMessageColor: '#e56767',
          promoAmount: 0,
          total: this.state.subtotal,
        });
    })
    this.setState({ processing: false });
  }

  render() {
    return (
      <div id="booking">
        { (this.state.width < 480) ?  (
          <MobileHead link={this.state.link} />
        ) : (
          <h1 className="logo_fullScreen">Scenerie</h1>
        )}
        <Slider2 object={this.state.images} width={2000} />
        <div className="bookingSummary_content desktopWrapper">
          <h1>Booking Summary</h1>
          <h3>{this.state.object.title}</h3>
          <hr/>
          <div className="bookingSummary_overview overview_details_heading">
            <p>Check-in</p>
            <p>Check-out</p>
            <p>Guests</p>
          </div>
          <div className="bookingSummary_overview overview_details_data">
            <p>{getMonth(this.state.startDate)}/{getDay(this.state.startDate)}</p>
            <p>{getMonth(this.state.endDate)}/{getDay(this.state.endDate)}</p>
            <p><pre>  2</pre></p>
          </div>
          <hr/>
          <div className="bookingSummary_overview overview_subtotal" >
            <p>Subtotal</p>
            <p>${this.state.subtotal}</p>
          </div>
          <div className="PromotionForm">
            <form className="Form" onSubmit={this.handleSubmit}>
              <div className="FormRow PromotionRow">
                <label htmlFor="promotion" className="promoLabel">
                  Promotion Code
                </label>
                <input
                  className="promoInput"
                  id="promotion"
                  type="text"
                  value={this.state.promoCode}
                  onChange={(e) => {
                    this.setState({promoCode: e.target.value.toUpperCase()});
                  }}
                />
              </div>
              <button
                className="promoButton"
                type="submit"
                style={{"display": this.state.promoCode === '' ? "none" : true}}
              >
                {this.state.processing ? 'Processing...' : 'Apply'}
              </button>
            </form>
            <p style={{"color": this.state.promoMessageColor}}>{this.state.promoMessage}</p>
          </div>
          <hr/>
          <div className="bookingSummary_overview overview_total" >
            <p>Total</p>
            <p>${this.state.total}</p>
          </div>
          <div className="bookingSummary_button">
            <Link
              to={{
                pathname: "/retreats/booking/2",
                state: {
                  object: this.state.object,
                  retreat: this.state.retreat,
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  total: this.state.total,
                  // only send promo code to next page if it's a valid one
                  promoCode: this.state.promoMessageColor === '#235745' ? this.state.promoCode : '',
                }}}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <button type="submit" onClick={this.book}>Book</button>
            </Link>
          </div>
          <div className="bookingSummary_included">
            <h3>{"What's included?"}</h3>
            <p>1. Your lodging</p>
            <p>2. Detailed itinerary & packing list*</p>
            <p>3. Daily activity cards*</p>
          </div>
          <p className="footnote">
            * Additional cost for activities such as meals, groceries etc. are not included
          </p>
        </div>
      </div>
    )
  }
}

export default withRouter(BookingSummary);
