import React, { Component } from "react";
import axios from "axios";


export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitlist: false,
      email: '',
      signupMessage: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.register = this.register.bind(this);
    this.registerBtn = React.createRef()
  }

  register(event) {
    event.preventDefault();
    this.registerBtn.current.disabled = true;
    axios.post('https://api.scenerie.co/api/signup-email', {
      email: this.state.email,
      waitlist: this.state.waitlist
    })
    .then(response => {
      if (this.state.waitlist === false) {
        window.open("/joinus?id=" + response.data.user_id, "_self");
      } else {
        window.open("/waitlist", "_self");
      }
    })
    .catch(err => {
        this.setState({
          signupMessage: 'Looks like your e-mail is already registered.',
        });
      this.registerBtn.current.disabled = false;
    })
  }

  handleChange(event) {
    this.setState({email: event.target.value});
  };

  //TODO: Empty email should not be allowed
  render() {
    return (
      <div id="waitlist" className="signup_intro" ref={this.props.intro}>
        <div id="waitlist" className="waitlistContent">
        <h2>Ready for your escape?</h2>
        <p>Discover all the amazing places around where you live. Get a new Scenerie Retreat straight to your inbox every week.</p>
        </div>
        <div id="waitlist" className="waitlistContainer">
        <form action="" method="post" className="form" onSubmit={this.register}>
          <input
            type="email"
            value={this.state.email}
            name="email"
            onChange={this.handleChange}
            placeholder="Your email"
          />
          <button type="submit" ref={this.registerBtn}>
            Join now!
          </button>
        </form>
        <p>{this.state.signupMessage}</p>
        </div>
      </div>
    );
  }
}
