import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

// FB Pixel
const options = {
  autoConfig: true,  // set pixel's autoConfig
  debug: false,  // enable logs
};
ReactPixel.init('1695148323952300', null, options);
ReactPixel.pageView();

// GA
const trackingId = "UA-149481452-1";
const history = createBrowserHistory();

ReactGA.initialize(trackingId);
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

/* ReactGA.set({
  userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
}) */


ReactDOM.render((
<Router history={history}>
    <App />
</Router>
), document.getElementById('root'));

serviceWorker.unregister();
