import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import './App.css';
import Home from './container/Home';
import SignupFlow from './container/SignupFlow';
import RetreatDetails from './container/Retreat';
import UpdatePreferences from './container/UpdatePreferences';
import Favorites from './container/Favorites';
import Admin from './components/Admin/Admin';
import AllPhotos from './components/Retreat/AllPhotos';
import Step1 from './components/Retreat/Booking/Step1';
import Step2 from './components/Retreat/Booking/Step2';
import Success from './components/Retreat/Booking/Success';
import Tagging from'./components/Admin/Tagging';
import HowItWorks from './components/Home/HowItWorks';
import TermsOfService from './components/Home/Termsofservice';
import PrivacyPolicy from './components/Home/Privacypolicy';
import AllSetWaitlist from './components/Signup/AllSetWaitlist';


class App extends Component {

  render() {
    return (
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route exact path='/joinus' component={SignupFlow}/>
        <Route exact path='/waitlist' component={AllSetWaitlist}/>
        <Route exact path='/retreats' component={RetreatDetails}/>
        <Route exact path='/retreats/photos' component={AllPhotos}/>
        <Route exact path='/retreats/booking/1' component={Step1}/>
        <Route exact path='/retreats/booking/2' component={Step2}/>
        <Route exact path='/retreats/booking/3' component={Success}/>
        <Route exact path='/admin' component={Admin}/>
        <Route exact path='/admin/tagging' component={Tagging}/>
        <Route exact path='/howitworks' component={HowItWorks}/>
        <Route exact path='/termsofservice' component={TermsOfService}/>
        <Route exact path='/privacypolicy' component={PrivacyPolicy}/>
        <Route exact path='/preferences' component={UpdatePreferences}/>
        <Route exact path='/favorites' component={Favorites}/>
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

export default App;
