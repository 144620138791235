import React, { Component } from "react";


export default class PropertyImages extends Component {

  render() {
    return (
      <div id="propertyImages">
      </div>
    )
  }
}
