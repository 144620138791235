import React, { Component } from 'react';
import {Image, Transformation} from 'cloudinary-react';
import { Link } from 'react-router-dom';

export default class AllSetWaitlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  startAtTop = () => window.scrollTo(0, 0)

  componentDidMount() {
    this.startAtTop()
  }

  render() {
    return (
      <div id="signup" className="signupFlow">
        <div className="signupFlow_header">
          <Link
            to={{ pathname: '/' }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <h1>Scenerie</h1>
          </Link>
        </div>
        <div className="allSetContainer">
          <h1>{"You're on the waitlist!"}</h1>
          <div className="allSetContent">
            <Image cloudName="scenerie" clientHints="true" publicId="signup/photo-1502248532929-dc0aaf17e885_xldqie" alt="You're on the waitlist!">
              <Transformation dpr="auto" width="auto:100:1000" fetchFormat="auto" crop="fit" />
            </Image>
            <p>{"We are slowly adding new members to our community, so watch out for an email from us."}</p>
          </div>
        </div>
      </div>
    )
  }
}
