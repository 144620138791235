import React, { Component } from 'react';
import Expandable3 from "./Expandable3";
import Slider2 from "../General/Slider2";

export default class AllDetails2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      images: this.props.object.property.images,
      activeCard: 1,
      className: 'one',
    }
}

componentDidMount() {
  this.setState({className: 'one'})
}

select(number) {
  if (number !== this.state.activeCard) {
    this.setState({activeCard: number}, () => {
      if (number === 1) {this.setState({className: 'one'})}
      if (number === 2) {this.setState({className: 'two'})}
    })
  } 
}

  render() {
    return (
      <div id="allDetails">
        <div className="detailsWrapper">
          <h2>Where you will stay</h2>
          <div className={"housing_details_nav " + this.state.className}>
            <button onClick={() => this.select(1)}>Photos</button>
            <button onClick={() => this.select(2)}>Other infos</button>
          </div>
          {(this.state.activeCard === 1) ? (
            <Slider2 object={this.state.images} width={1000} />
          ) : (
            <Expandable3 object={this.props.object} />
          )}
        </div>
      </div>
    )
  }
}
