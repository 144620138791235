import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { mapStyles } from '../Retreat/MapStyles';

const Marker = ({ src, alt, onClick }) => (
  <div className="marker">
    <img src={src} alt={alt} onClick={onClick} />
  </div>
);

export default class Location extends Component {

  createMapOptions = (maps) => {
    return {styles: mapStyles}
  }

  render() {
    return (
      <div id="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAxXN7DutALb8b5podO7BQMqx042SdZfNE", language: "en" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={this.createMapOptions}
        >
          {this.props.markers && this.props.markers.map((marker, i) =>
            <Marker
              key={marker.key}
              lat={marker.lat}
              lng={marker.lng}
              src={marker.src}
              alt={marker.alt}
              onClick={() => this.props.onClick(marker.id, marker.type)}
            />
          )}
        </GoogleMapReact>
      </div>
    )
  }
}
