import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class AllPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      images: this.props.location.state.images
    };
  }

  startAtTop = () => window.scrollTo(0, 0);

  componentDidMount() {
      this.setState({isLoading: false});
      this.startAtTop()

        const options = {
          root: document.querySelector("#allPhotos"),
          threshold: 0.5,
        };
    
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting === true) {
                console.log(entry.target.id);
                if (document.querySelector(".is-active")) {
                  document.querySelector(".is-active").classList.add("is-inactive");
                  document.querySelector(".is-active").classList.remove("is-active");
                }
                document.querySelectorAll(".is-inactive")[entry.target.id].classList.add("is-active");
                document.querySelectorAll(".is-inactive")[entry.target.id].classList.remove("is-inactive");
              }
            })
          },
          options
        );
        document.querySelectorAll('.allPhotos_slider_content').forEach(element => observer.observe(element));
  }

  render() {
    console.log(this.state)
    return (
      <div id="allPhotos">
        <div>
          <Link to="/retreats" style={{ textDecoration: 'none', color: 'black'}} className="allPhotos_goBack">
            <img src="/img/icons/arrow_grey_left.png" alt="Back"/>
            <p>Back</p>
          </Link>
        </div>
        <div className="allPhotos_slider">
        {this.state.images.map((eachObject, index) => {
          return (
            <div className="allPhotos_slider_content" key={eachObject.id} id={index} value={eachObject.id}>
              <img src={eachObject.img} alt=""/>
              <h4>{eachObject.description}</h4>   
            </div>
            )
           })}
        </div>
        <ul className="allPhotos_highlights_list">
        {this.state.images.map((_) =>
          <li className="is-inactive"></li>
        )}
        </ul>
        <div>

        </div>
      </div>
    )
  }
}
